<template>
  <v-row justify="center" v-if="area != null">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dense :color="hexTints(area.cor,0.95)">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="f-opensans fsp-n1 fw-500 fs-13pt">Detalhes de entregas da área</v-toolbar-title>
          <div class="ms-5">
            <tagarea class="me-1" :sigla="area.sigla"/>
          </div>
          <div class="mb-0 ms-1 pt-1 f-roboto fs-12pt fw-600 text-truncate" v-cHex="hexShades(area.cor,0.2)">
            {{area.nome}}
          </div>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="mt-1 mx-1">
          <v-col cols="12" md="4" lg="3">
            <areacard :area="area" :button="false" :key="area.id"/>
            <!-- <progressmulti /> -->
          </v-col>

          <v-col cols="12" md="12" lg="9">
            <v-text-field class="my-0 pb-0" placeholder="filtrar por nome" v-model="filtro" dense solo>
              <template v-slot:append>
                <i v-i:ic#search#26></i>
              </template>
            </v-text-field>
            <p class="m-0 p-0 mt-n5 mb-2 f-roboto fs-9pt fw-300">
              <i v-i:duo#warning#16 class="me-1"></i>
              Clique na UC para ver detalhes
            </p>
            <div class="p-0 mb-2" v-for="(uc,index) in ucsFiltradas" :key="index">
              <ucitem :ucID="uc.id" :profsCientes="profsCientes" :area="area" @clickUC="clickUC"/>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <ucdetalhes ref="ucdetalhesref"/>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import progressmulti from "./progress-multi.vue";
import tagarea from "@/views/cursos/tagArea.vue";
import areacard from "./area-card.vue";
import ucitem from "./uc-item.vue";
import ucdetalhes from "./uc-detalhes.vue"

export default {
  name: "",
  components: { progressmulti, tagarea, areacard, ucitem, ucdetalhes },
  props: {},
  computed: {},
  data(){
    return {
      dialog: false,
      loading: false,
      refresh: 0,
      area: null,
      filtro: "",
      encomendas: {},
      entregas: {},
      ucs: {},
      ucsFiltradas: {},
      profs: {},
      profsCientes: {},
      qtde: { encomendas: 0, entregas: 0, naoVisualizados: 0, ucs: 0, profs: 0, revisao: 0, correcao: 0, liberado: 0 },
    }
  },
  watch: {
    filtro() {
      this.filtrar();
    },
  },
  mounted: function() {
    var self = this;

    rdb.ref('/curriculo/avaliacao/2021S2/profsCientes').on('value',function(snapshot) {
      self.profsCientes = snapshot.val();
      console.log("self.profsCientes ["+Object.keys(self.profsCientes).length+"]",self.profsCientes);
      self.build();
    });

  },
  methods:{

    build() {
      var self = this;

      if(this.area != null) {

        rdb.ref('/curriculo/avaliacao/2021S2/encomendasA2').orderByChild("area"+self.area.sigla).equalTo(true).on('value',function(snapshot) {
          self.encomendas = snapshot.val();
          if(self.encomendas != null) {
            console.log("self.encomendas ["+Object.keys(self.encomendas).length+"]",self.encomendas);
            self.ucs = {};
            for(var idx in self.encomendas) {
              self.ucs[self.encomendas[idx].ucID] = {
                id: self.encomendas[idx].ucID,
                nome: self.encomendas[idx].nomeUC
              };
            }
            console.log("self.ucs ["+Object.keys(self.ucs).length+"]",self.ucs);
            self.filtrar();
          } else {
            self.encomendas = {};
          }
          self.qtde.encomendas = Object.keys(self.encomendas).length;
          rdb.ref('/curriculo/avaliacao/2021S2/entregasA2').orderByChild("area"+self.area.sigla).equalTo(true).on('value',function(snapshot) {
            self.entregas = snapshot.val();
            if(self.entregas != null) {
              console.log("self.entregas ["+Object.keys(self.entregas).length+"]",self.entregas);
            } else {
              self.entregas = {};
            }
            self.qtde.entregas = Object.keys(self.entregas).length;
          });
        });
      }
    },

    filtrar() {
      var self = this;
      console.log("self.filtro",self.filtro);
      console.log("self.ucs",self.ucs);
      if(this.filtro.trim() == "") {
        self.ucsFiltradas = self.ucs;
      } else {
        self.ucsFiltradas = {};
        for(var key in self.ucs) {
          if(self.ucs[key].nome != undefined) {
            if(self.ucs[key].nome.toUpperCase().indexOf(self.filtro.trim().toUpperCase()) != -1) {
              self.ucsFiltradas[key] = self.ucs[key];
            }
          }
        }
      }

    },

    show(area) {
      this.area = area;
      this.dialog = true;
      this.build();
    },

    areaClick() {

    },

    clickUC(dados) {
      console.log("clickUC",dados);
      console.log("oi");
      this.$refs.ucdetalhesref.show(dados);
    }

  }
}
</script>

<style scoped>
</style>
