<template>
  <v-container>
    <v-row dense align="center" justify="space-between">
      <v-col cols="12" md="7">
        <v-card class="p-1 px-2 text-justify fs-9pt fw-300 f-roboto">
          <span class="f-roboto fw-500 fs-8pt">ID:</span>
          <span class="ms-1 f-roboto fw-500 fs-8pt">{{meta.metaCodigo}}</span>
          {{meta.texto}}
        </v-card>
      </v-col>
      <v-col cols="12" md="1" class="text-center">
        <span v-cHex="'#0a678f'" class="fs-11pt f-lato fw-600">{{Object.keys(encomenda).length}}</span>
      </v-col>
      <v-col cols="12" md="4" class="text-left">
        <v-container class="m-0 p-0" v-for="(item,index,key) in encomenda" :key="key">
          <v-row no-gutters align="center" justify="start">
            <v-col cols="">
              <span class="f-roboto fw-500 fs-7pt">ID Questão:</span>
              <span class="ms-1 f-roboto fw-500 fs-8pt" v-if="item.entrega == undefined">----</span>
              <span class="ms-1 f-roboto fw-500 fs-8pt" v-if="item.entrega != undefined">
                {{item.entrega.questaoID}}
              </span>
            </v-col>
            <v-col cols="">
              <span class="ms-1 f-roboto fw-500 fs-8pt" v-cHex="'#808080'" v-if="item.entrega == undefined">
                <span class="ms-1 me-1 dot" style="background-color: #808080"></span>
                Aguardando
              </span>
              <span class="ms-1 f-opensans fsp-0 fw-600 fs-9pt" v-cHex="'#22a76f'" v-if="item.entrega != undefined && item.entrega.revisaoStatus=='Revisão'">
                <span class="ms-1 me-1 dot" style="background-color: #22a76f"></span>
                {{item.entrega.revisaoStatus}}
              </span>
              <span class="ms-1 f-opensans fsp-0 fw-600 fs-9pt" v-cHex="'#a72222'" v-if="item.entrega != undefined && item.entrega.revisaoStatus=='Para correção'">
                <span class="ms-1 me-1 dot" style="background-color: #a72222"></span>
                {{item.entrega.revisaoStatus}}
              </span>
              <span class="ms-1 f-opensans fsp-0 fw-600 fs-9pt" v-cHex="'#125036'" v-if="item.entrega != undefined && item.entrega.revisaoStatus=='Liberadas para aplicação'">
                <span class="ms-1 me-1 dot" style="background-color: #125036"></span>
                Liberada
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "encomendaitem",
  components: {},
  props: {
    encomenda: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      meta: {},
      encomendas: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;
    console.log("encomenda",self.encomenda);
    for(var idx in self.encomenda) {
      var metaID = self.encomenda[idx].meta;
    }
    console.log(metaID);
    rdb.ref('/curriculo/metas/'+metaID).on('value',function(snapshot) {
      self.meta = snapshot.val();
      console.log("self.meta",self.meta);
      self.build();
    });
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
