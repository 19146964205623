<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-toolbar :color="cor.active.b">
        <v-btn class="ms-1 me-0 pe-0 ps-0" icon small v-bg:S#3 @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-600 fs-11pt">
          Detalhes da entrega
        </v-toolbar-title>
        <div class="ms-5">
          <p class="m-0 p-0 ms-1 f-opensans fw-500 fsp-n1 fs-11pt line-height-2">
            {{uc.nome}}
          </p>
          <p class="m-0 p-0 ms-1 f-sanspro fw-300 fs-11pt line-height-3">
            <span v-for="(area, key, index) in uc.areas" :key="key">
              <tagarea class="me-1" :sigla="area.sigla"/>
            </span>
          </p>
        </div>
      </v-toolbar>
      <v-card-text>
        <v-card class="mt-3 p-2" :color="hexTints(cor.active.I,0.9)">
          <v-container>
            <v-row>
              <v-col cols="6" sm="2" md="2" lg="2" class="p-0 pe-3 ps-3 m-0 text-center">
                <p class="m-0 p-0 f-roboto fs-7pt fsp-0 fw-400 line-height-2">Encomenda</p>
                <p class="m-0 p-0 line-height-1">
                  <span class="f-lato fs-10pt fw-500 fsp-n1">{{qtde.encomendas}}</span>
                  <span class="ms-1 f-lato fs-9pt fw-300 fsp-0">itens</span>
                </p>
                <p class="m-0 p-0 line-height-1">
                  <span class="f-lato fs-9pt fw-500 fsp-n1">{{qtde.profs}}</span>
                  <span class="ms-1 f-lato fs-8pt fw-300 fsp-0">profs</span>
                </p>
              </v-col>
              <v-col cols="6" sm="9" md="2" lg="3" class="p-0 m-0 px-3 line-height-2">
                <span class="ms-2 f-sanspro fs-10pt fw-600">{{qtde.liberado}}</span>
                <span class="ms-1 f-sanspro fs-10pt fw-300">itens</span>
                <span class="ms-3 f-sanspro fs-10pt ms-1 fw-700" v-cHex="'#125036'">{{porcLiberados}}%</span>
                <div class="m-0 p-0 mt-0 ms-0 f-sanspro fw-300 fs-11pt line-height-3">
                  <v-progress-linear rounded height="6" :value="porcLiberados" color="#125036"></v-progress-linear>
                </div>
                <p class="m-0 mt-1 p-0 ms-0 f-sanspro fw-400 fs-10pt line-height-2 text-center" v-cHex="'#125036'">
                  Liberados
                </p>
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" class="p-0 m-0 px-3 line-height-2">
                <span class="ms-2 f-sanspro fs-10pt fw-600">{{qtde.revisao}}</span>
                <span class="ms-1 f-sanspro fs-10pt fw-300">itens</span>
                <span class="ms-3 f-sanspro fs-10pt ms-1 fw-800" v-cHex="'#125036'">{{emRevisao}}%</span>
                <div class="m-0 p-0 mt-0 f-sanspro fw-300 fs-11pt line-height-3">
                  <v-progress-linear rounded height="6" :value="emRevisao" color="#22a76f"></v-progress-linear>
                </div>
                <p class="m-0 p-0 mt-1 f-sanspro fw-400 fs-10pt line-height-2 text-center" v-cHex="hexShades('#22a76f',0.3)">
                  Revisão
                </p>
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="2" class="p-0 m-0 px-3 line-height-2">
                <span class="ms-2 f-sanspro fs-10pt fw-600">{{qtde.correcao}}</span>
                <span class="ms-1 f-sanspro fs-10pt fw-300">itens</span>
                <span class="ms-3 f-sanspro fs-10pt ms-1 fw-800" v-cHex="'#125036'">{{emCorrecao}}%</span>
                <div class="m-0 p-0 mt-0 f-sanspro fw-300 fs-11pt line-height-3">
                  <v-progress-linear rounded height="6" :value="emCorrecao" color="#9e2a00"></v-progress-linear>
                </div>
                <p class="m-0 p-0 mt-1 f-sanspro fw-400 fs-10pt line-height-2 text-center" v-cHex="hexShades('#9e2a00',0.3)">
                  Correção
                </p>
              </v-col>
              <v-col cols="6" sm="3" md="2" lg="2" class="p-0 m-0 px-1 line-height-2">
                <span class="ms-2 f-sanspro fs-10pt fw-600">{{qtde.naoVisualizados}}</span>
                <span class="ms-1 f-sanspro fs-10pt fw-300">itens</span>
                <span class="ms-3 f-sanspro fs-10pt ms-1 fw-800" v-cHex="'#808080'">{{porcNaoVisualizados}}%</span>
                <div class="m-0 p-0 ms-0 f-sanspro fw-300 fs-11pt line-height-3">
                  <v-progress-linear rounded height="6" :value="porcNaoVisualizados" color="#808080"></v-progress-linear>
                </div>
                <p class="m-0 p-0 ms-0 mt-1 f-sanspro fw-400 fs-10pt line-height-2 text-center" v-cHex="'#808080'">
                  Não visualizados
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider class="my-3"></v-divider>
        <v-expansion-panels class="px-3 py-2 mt-2" v-bg:b>
          <profitem :userID="prof" :encomendas="encomendas" :key="refresh+index+key" v-for="(prof,index,key) in profs"/>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "@/views/cursos/tagArea.vue";
import profitem from "./prof-item.vue";

export default {
  name: "",
  components: { tagarea, profitem },
  props: [],
  computed: {
    emRevisao() {
      return parseFloat(this.qtde.revisao / (this.qtde.encomendas-this.qtde.liberado) * 100).toFixed(1);
    },
    emCorrecao() {
      return parseFloat(this.qtde.correcao / (this.qtde.encomendas-this.qtde.liberado) * 100).toFixed(1);
    },
    porcLiberados() {
      return parseFloat(this.qtde.liberado / (this.qtde.encomendas) * 100).toFixed(1);
    },
    porcNaoVisualizados() {
      return parseFloat(this.qtde.naoVisualizados / (this.qtde.encomendas) * 100).toFixed(1);
    },
    porcVisualizados() {
      return parseFloat(100-(this.qtde.naoVisualizados / (this.qtde.encomendas) * 100)).toFixed(1);
    },
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      dialog: false,
      uc: {},
      qtde: {},
      profs: {},
      encomendas: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    show(dados) {
      console.log("dados",dados);
      this.uc = dados.uc;
      this.qtde = dados.qtde;
      this.profs = dados.profs;
      this.encomendas = dados.encomendas;
      this.refresh++;
      this.dialog = true;
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>
</style>
