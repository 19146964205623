<template>
  <v-expansion-panel class="m-0 mb-1 ps-0 py-0" :color="hexTints(cor.active.b,.5)" v-if="user != null">
    <v-expansion-panel-header class="m-0 ps-2 pe-2 py-0" :color="hexTints(cor.active.b,.5)">
      <div class="my-1 flexb justify-start falign-center" >
        <div class="px-1" style="">
          <v-avatar size="30">
            <v-img :src="user.urlFoto"></v-img>
          </v-avatar>
        </div>
        <div class="px-1 f-lato fs-9pt fw-400 mt-1 w220 text-truncate">
          {{user.nome}}
          <p class="m-0 p-0 fs-opensans fw-300" v-if="!ciente" :key="refresh" v-c:D>
            <i v-i:duo#warning#14></i>
            Não visualizou entrega no App
          </p>
        </div>
        <div class="px-1 mt-0" v-cHex="'#0a678f'">
          <span class="f-sanspro fs-11pt fw-600">{{qtde.encomendas}}</span>
          <span class="ms-1 f-sanspro fs-10pt fw-300">encomendas</span>
        </div>
        <div class="px-1 mt-0 flex1">
          <progressmulti :height="14" :values="[emRevisao,emCorrecao,porcLiberados]" :colors="['#22a76f','#9e2a00','#125036']" :labels="['Revisão', 'Correção', 'Liberados']" />
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="p-0 mb-n4 ms-n6 me-n6">
      <v-card flat class="m-0 p-0 mt-n2 pb-1" :color="cor.active.B">
        <div class="me-2 text-right" v-for="(encomenda,index,key) in minhasEncomendas" :key="key">
          <encomendaitem :encomenda="encomenda"/>
        </div>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>

</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import progressmulti from "./progress-multi.vue";
import encomendaitem from "./encomenda-item.vue";

export default {
  name: "profitem",
  components: { progressmulti, encomendaitem },
  props: {
    userID: { default: "xxxx", type: String },
    encomendas: { default: null, type: Object }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      user: null,
      ciente: false,
      minhasEncomendas: {},
      entregas: {},
      qtde: { encomendas: 0, entregas: 0, naoVisualizados: 0, ucs: 0, profs: 0, revisao: 0, correcao: 0, liberado: 0 },
    }
  },
  computed: {
    emRevisao() {
      return parseFloat(this.qtde.revisao / (this.qtde.encomendas-this.qtde.liberado) * 100).toFixed(1);
    },
    emCorrecao() {
      return parseFloat(this.qtde.correcao / (this.qtde.encomendas-this.qtde.liberado) * 100).toFixed(1);
    },
    porcLiberados() {
      return parseFloat(this.qtde.liberado / (this.qtde.encomendas) * 100).toFixed(1);
    },
    porcNaoVisualizados() {
      return parseFloat(this.qtde.naoVisualizados / (this.qtde.encomendas) * 100).toFixed(1);
    },
    porcVisualizados() {
      return parseFloat(100-(this.qtde.naoVisualizados / (this.qtde.encomendas) * 100)).toFixed(1);
    },
  },
  watch: {
    userID() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    self.build();

    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
      console.log("this.userID",this.userID);
      if(this.userID != "") {

        rdb.ref('/curriculo/avaliacao/2021S2/profsCientes/'+this.userID).on('value',function(snapshot) {
          if(snapshot.val() != null) {
            self.ciente = true;
          } else {
            self.ciente = false;
          }
          self.refresh++;
          self.$forceUpdate();
        });

        rdb.ref('/usuarios/pessoas/'+this.userID).on('value',function(snapshot) {
          self.user = snapshot.val();
          //console.log("self.user",self.user);
          //self.build();
          //console.log("self.encomendas",self.encomendas);
          rdb.ref('/curriculo/avaliacao/2021S2/entregasA2').orderByChild("autorID").equalTo(self.userID).on('value',function(snapshot) {
            self.entregas = snapshot.val();
            console.log("self.entregas",self.entregas);
            if(self.entregas == null) {
              self.entregas = {};
            }
            self.minhasEncomendas = {};
            self.qtde.encomendas = 0;
            self.qtde.revisao = 0;
            self.qtde.correcao = 0;
            self.qtde.liberado = 0;
            for(var idx in self.encomendas) {
              if(self.encomendas[idx].profID == self.userID) {
                self.qtde.encomendas++;
                if(self.minhasEncomendas[self.encomendas[idx].meta] == undefined) {
                  self.minhasEncomendas[self.encomendas[idx].meta] = {};
                }
                self.minhasEncomendas[self.encomendas[idx].meta][self.encomendas[idx].id] = self.encomendas[idx];
                for(var key in self.entregas) {
                  if(self.entregas[key].usada == undefined) {
                    if(self.entregas[key].metaID == self.encomendas[idx].meta) {
                      self.minhasEncomendas[self.encomendas[idx].meta][self.encomendas[idx].id].entrega = self.entregas[key];
                      self.entregas[key].usada = true;
                      if(self.entregas[key].revisaoStatus == "Revisão") {
                        self.qtde.revisao++;
                      }
                      if(self.entregas[key].revisaoStatus == "Para correção") {
                        self.qtde.correcao++;
                      }
                      if(self.entregas[key].revisaoStatus == "Liberadas para aplicação") {
                        self.qtde.liberado++;
                      }
                    }
                  }
                }
              }
            }
            console.log("self.minhasEncomendas",self.minhasEncomendas);
            console.log("self.entregas",self.entregas);

          });
        });
      }
    },

  }
}
</script>

<style scoped>
</style>
