<template>
  <v-card v-if="uc != null" :key="''+refresh+uc.id" @click="$emit('clickUC',{ uc: uc, qtde: qtde, profs: profs, encomendas: encomendas })">
    <v-skeleton-loader v-if="loading" type="list-item"></v-skeleton-loader>
    <v-container v-if="!loading" class="p-2 m-0">
      <v-row no-gutters align="center" justify="start">
        <v-col cols="1" lg="auto" class="p-0 m-0 pe-1" style="">
          <v-card class="px-1 py-0 h44 w40" flat :color="hexTints(area.cor,0.93)">
            <i v-i:duo#graduation-cap#26 class="ms-1" v-cHex="area.cor"></i>
            <span class="absolute fs-10pt fw-900 f-lato" v-cHex="area.cor" style="left: 12px; bottom: 0px;">UC</span>
          </v-card>
        </v-col>
        <v-col cols="11" lg="3" class="p-0 m-0 text-truncate">
          <p class="m-0 p-0 ms-1 f-opensans fw-500 fsp-n1 fs-11pt line-height-2">
            {{uc.nome}}
          </p>
          <p class="m-0 p-0 ms-1 f-sanspro fw-300 fs-11pt line-height-3">
            <span v-for="(area, key, index) in uc.areas" :key="key">
              <tagarea class="me-1" :sigla="area.sigla"/>
            </span>
          </p>
        </v-col>
        <v-col cols="6" sm="2" md="2" lg="auto" class="p-0 pe-3 ps-3 m-0 text-center">
          <p class="m-0 p-0 f-roboto fs-7pt fsp-0 fw-400 line-height-2">Encomenda</p>
          <p class="m-0 p-0 line-height-1">
            <span class="f-lato fs-10pt fw-500 fsp-n1">{{qtde.encomendas}}</span>
            <span class="ms-1 f-lato fs-9pt fw-300 fsp-0">itens</span>
          </p>
          <p class="m-0 p-0 line-height-1">
            <span class="f-lato fs-9pt fw-500 fsp-n1">{{qtde.profs}}</span>
            <span class="ms-1 f-lato fs-8pt fw-300 fsp-0">profs</span>
          </p>
        </v-col>
        <v-col cols="6" sm="9" md="2" lg="2" class="p-0 m-0 px-3 line-height-2">
          <span class="ms-2 f-sanspro fs-10pt fw-600">{{qtde.liberado}}</span>
          <span class="ms-1 f-sanspro fs-10pt fw-300">itens</span>
          <span class="ms-3 f-sanspro fs-10pt ms-1 fw-700" v-cHex="'#125036'">{{porcLiberados}}%</span>
          <div class="m-0 p-0 mt-0 ms-0 f-sanspro fw-300 fs-11pt line-height-3">
            <v-progress-linear rounded height="6" :value="porcLiberados" color="#125036"></v-progress-linear>
          </div>
          <p class="m-0 mt-1 p-0 ms-0 f-sanspro fw-400 fs-10pt line-height-2 text-center" v-cHex="'#125036'">
            Liberados
          </p>
        </v-col>
        <v-col cols="6" sm="3" md="3" lg="2" class="p-0 m-0 px-3 line-height-2">
          <span class="ms-2 f-sanspro fs-10pt fw-600">{{qtde.revisao}}</span>
          <span class="ms-1 f-sanspro fs-10pt fw-300">itens</span>
          <span class="ms-3 f-sanspro fs-10pt ms-1 fw-800" v-cHex="'#125036'">{{emRevisao}}%</span>
          <div class="m-0 p-0 mt-0 f-sanspro fw-300 fs-11pt line-height-3">
            <v-progress-linear rounded height="6" :value="emRevisao" color="#22a76f"></v-progress-linear>
          </div>
          <p class="m-0 p-0 mt-1 f-sanspro fw-400 fs-10pt line-height-2 text-center" v-cHex="hexShades('#22a76f',0.3)">
            Revisão
          </p>
        </v-col>
        <v-col cols="6" sm="3" md="3" lg="2" class="p-0 m-0 px-3 line-height-2">
          <span class="ms-2 f-sanspro fs-10pt fw-600">{{qtde.correcao}}</span>
          <span class="ms-1 f-sanspro fs-10pt fw-300">itens</span>
          <span class="ms-3 f-sanspro fs-10pt ms-1 fw-800" v-cHex="'#125036'">{{emCorrecao}}%</span>
          <div class="m-0 p-0 mt-0 f-sanspro fw-300 fs-11pt line-height-3">
            <v-progress-linear rounded height="6" :value="emCorrecao" color="#9e2a00"></v-progress-linear>
          </div>
          <p class="m-0 p-0 mt-1 f-sanspro fw-400 fs-10pt line-height-2 text-center" v-cHex="hexShades('#9e2a00',0.3)">
            Correção
          </p>
        </v-col>
        <v-col cols="6" sm="3" md="2" lg="auto" class="p-0 m-0 px-1 line-height-2">
          <span class="ms-2 f-sanspro fs-10pt fw-600">{{qtde.naoVisualizados}}</span>
          <span class="ms-1 f-sanspro fs-10pt fw-300">itens</span>
          <span class="ms-3 f-sanspro fs-10pt ms-1 fw-800" v-cHex="'#808080'">{{porcNaoVisualizados}}%</span>
          <div class="m-0 p-0 ms-0 f-sanspro fw-300 fs-11pt line-height-3">
            <v-progress-linear rounded height="6" :value="porcNaoVisualizados" color="#808080"></v-progress-linear>
          </div>
          <p class="m-0 p-0 ms-0 mt-1 f-sanspro fw-400 fs-10pt line-height-2" v-cHex="'#808080'">
            Não visualizados
          </p>
        </v-col>
        <!--
        <v-col cols="1" class="p-0 m-0 text-center">
        <v-btn color="" x-small outlined v-bg:b class="fs-7pt fw-400 px-1">
        detalhes
      </v-btn>
    </v-col> -->
  </v-row>
</v-container>
</v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "@/views/cursos/tagArea.vue";

export default {
  name: "ucitem",
  components: { tagarea },
  props: {
    ucID: { default: "", type: String },
    area: { default: null, type: Object },
    profsCientes: { default: null, type: Object },
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      uc: {},
      encomendas: {},
      entregas: {},
      profs: {},
      qtde: { encomendas: 0, entregas: 0, naoVisualizados: 0, ucs: 0, profs: 0, revisao: 0, correcao: 0, liberado: 0 },
    }
  },
  computed: {
    emRevisao() {
      return parseFloat(this.qtde.revisao / (this.qtde.encomendas-this.qtde.liberado) * 100).toFixed(1);
    },
    emCorrecao() {
      return parseFloat(this.qtde.correcao / (this.qtde.encomendas-this.qtde.liberado) * 100).toFixed(1);
    },
    porcLiberados() {
      return parseFloat(this.qtde.liberado / (this.qtde.encomendas) * 100).toFixed(1);
    },
    porcNaoVisualizados() {
      return parseFloat(this.qtde.naoVisualizados / (this.qtde.encomendas) * 100).toFixed(1);
    },
    porcVisualizados() {
      return parseFloat(100-(this.qtde.naoVisualizados / (this.qtde.encomendas) * 100)).toFixed(1);
    },
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;
    rdb.ref('/curriculo/ucs/'+this.ucID).on('value',function(snapshot) {
      self.uc = snapshot.val();
      console.log("self.uc",self.uc);
      self.refresh++;

      rdb.ref('/curriculo/avaliacao/2021S2/encomendasA2').orderByChild("ucID").equalTo(self.ucID).on('value',function(snapshot) {
        self.encomendas = snapshot.val();
        if(self.encomendas != null) {
          //console.log("self.encomendas ["+Object.keys(self.encomendas).length+"]",self.encomendas);
        } else {
          self.encomendas = {};
        }
        self.qtde.encomendas = Object.keys(self.encomendas).length;
        rdb.ref('/curriculo/avaliacao/2021S2/entregasA2').orderByChild("ucID").equalTo(self.ucID).on('value',function(snapshot) {
          self.entregas = snapshot.val();
          if(self.entregas != null) {
            //console.log("self.entregas ["+Object.keys(self.entregas).length+"]",self.entregas);
          } else {
            self.entregas = {};
          }
          self.qtde.entregas = Object.keys(self.entregas).length;
          self.build();
        });
      });
    });
  },
  methods:{

    build() {
      var self = this;
      self.loading = false;
      self.ucs = {};
      for(var key in self.encomendas) {
        var ucID = self.encomendas[key].ucID;
        var profID = self.encomendas[key].profID;
        self.ucs[ucID] = ucID;
        self.profs[profID] = profID;
      }
      self.qtde.ucs = Object.keys(self.ucs).length;
      self.qtde.profs = Object.keys(self.profs).length;

      self.qtde.revisao = 0;
      self.qtde.correcao = 0;
      self.qtde.liberado = 0;
      for(var key in self.entregas) {
        //console.log(self.entregas[key].revisaoStatus);
        if(self.entregas[key].revisaoStatus == "Revisão") {
          self.qtde.revisao++;
        }
        if(self.entregas[key].revisaoStatus == "Para correção") {
          self.qtde.correcao++;
        }
        if(self.entregas[key].revisaoStatus == "Liberadas para aplicação") {
          self.qtde.liberado++;
        }
      }

      var cientes = 0;
      for(var key in self.encomendas) {
        if(self.profsCientes[self.encomendas[key].profID] != undefined) {
          cientes++;
        }
      }
      self.qtde.naoVisualizados = self.qtde.encomendas - cientes;

      console.log("qtde",self.qtde);

    }
  }
}
</script>

<style scoped>
</style>
